.resources__section {
    padding: 200px 0;
    background: #13142c;
}
.resource__hero {
    padding: 0 110px 200px;
    color: var(--sub-white-color);
    background-image: url("../../../public/images/Group.svg");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 100px;
}
.resource__hero h3 {
    font-weight: 700;
    font-size: 68px;
    line-height: 76px;
    margin: 20px 0;
}
.resource__hero p {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    width: 964px;
}

.resources__section .resource__section {
    width: 1159px;
    margin: 0 auto 50px;
}
.resources__section .resource__section .resource__logo {
    width: 100%;
    height: 133px;
}
.resources__section .instant .resource__logo img {
    height: 243px;
    width: 100%;
}
.resources__section .resource__steps {
    background: #191a38;
    box-shadow: 0px 5px 16px #12122c;
    border-radius: 0px 0px 10px 10px;
    padding: 40px 40px 70px;
    gap: 10px;
    color: var(--sub-white-color);
}
.resources__section .resource__steps .step__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0 20px;
}

.resource__section .resource__steps .step__head i {
    background: #1e1f41;
    box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
    border-radius: 25px;
    /* transform: rotate(90deg); */
    padding: 10px;
    cursor: pointer;
}
.resource__section .resource__steps .step__head h5 {
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
}
.resource__section .resource__steps .step__body ul {
    padding-left: 20px;
}
.resource__section .resource__steps .step__body ul li,
.resource__section .step__body p {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    list-style-type: circle;
    margin-bottom: 15px;
    width: 984px;
    color: #9898b4;
}
.divit .resource__logo {
    background: #fff;
}
.slapa__token .resource__logo {
    background: #000;
}
.divit .resource__logo,
.one__step .resource__logo,
.slapa__token .resource__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0px 0px;
}
.one__step .resource__logo {
    background-image: url("../../../public/images/one__step.svg");
}

.resource__section h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #b0b0bd;
    margin: 30px 0;
}

.social__links img {
    width: 24.6px;
    height: 24.6px;
    margin-right: 8px;
    cursor: pointer;
}
.social__links .level__one {
    margin: 20px 0;
}


@media (max-width: 912px) {
    .resource__hero {
        padding: 0 20px;
    }
    .resource__hero h3 {
        font-size: 40px;
    }
    .resource__hero p {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
    }
    .resources__section .resource__section {
        width: 100%
    }
    .resources__section .resource__steps {
        padding: 40px 20px
    }
    .resource__section .resource__steps .step__body ul li, .resource__section .step__body p {
        width: 100%;
    }
}