@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
:root {
    --sub-white-color: #fdfdfd
}

@font-face {
    font-family: chromate;
    src: url('./assets/fonts/Chromate-Regular.ttf');
    font-weight: bold;
}

a:-webkit-any-link {
    text-decoration: none;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5%;
    position: fixed;
    width: 100%;
    background-color: #FCFCFD;
    z-index: 100;
}

.logo a {
    font-family: chromate;
    font-size: 20px;
    color: #000;
    text-decoration: none;
}

.header_btns button {
    padding: 15px 30px;
    border: none;
    color: #30345A;
    margin-right: 20px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    font-family: Nunito, sans-serif;
    transition: all 0.3s linear;
}

.header_btns button:hover {
    background-color: rgb(239, 223, 223);
    transition: all 0.3s linear;
}

.header_btns a:nth-child(1) button {
    color: #fff;
    background-color: #9757D7;
}

.header_btns a:nth-child(1):hover button {
    background-color: #49078c;
    transition: all 0.3s linear;
}

.header_btns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_btns img {
    cursor: pointer;
}

.menu {
    display: none;
}

.signin_bar{
    display: none;
}

.container {
    padding: 30px 5%;
}

.container h2 {
    font-family: chromate;
    font-size: 35px;
}

.container p {
    font-family: Nunito, sans-serif;
    width: 300px;
}

.back a {
    display: flex;
    justify-content: left;
    cursor: pointer;
    align-items: center;
    font-family: chromate;
    text-decoration: none;
    margin: 50px 0 -30px 100px;
    color: #000;
    width: 100px;
}

.back a:hover {
    color: #49078c;
}


.hero {
    background-image: url('./assets/Herobg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #fff;
}

.hero img {
    height: 250px;
    margin: 100px 0 10px;
}

.hero h2 {
    margin: auto;
    width: 400px;
}

.hero p {
    margin: 20px auto;
    width: 500px;
    line-height: 24px;
}

.hero button {
    width: 200px;
    padding: 15px 30px;
    border: none;
    color: #fff;
    background-color: #9757D7;
    margin-right: 20px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    font-family: Nunito, sans-serif;
    transition: all 0.3s linear;
    margin: 10px 0 30px;
}

.hero button:hover {
    background-color: #49078c;
    transition: all 0.3s linear;
}


.details {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 100px 0;
}

.details img {
    height: 300px;
}

.details p {
    font-size: 16px;
    margin: 30px 0;
    width: 360px;
    line-height: 24px;
    color: #777E91;
}

.details button {
    width: 200px;
    padding: 15px 30px;
    border: none;
    color: #fff;
    background-color: #9757D7;
    margin-right: 20px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    font-family: Nunito, sans-serif;
    transition: all 0.3s linear;
    margin: 10px 0 30px;
}

.details button:hover {
    background-color: #49078c;
    transition: all 0.3s linear;
}




footer {
    background: linear-gradient(180deg, #0F1021 0%, #111227 100%);
}

.footer-top {
    margin: auto;
    width: 1000px;
    background: transparent;
    text-align: center;
    padding-top: 100px;
}


.footer-top small {
    text-align: center;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #666DFF;
}

.footer-top h4 {
    margin-top: 10px;
    font-size: 32px;
    color: #fff;
    font-weight: normal;
}

.footer-top p {
    background: transparent;
    width: 550px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #F5F5FF;
    margin: 30px auto 20px;
    line-height: 30px;
}

.footer-mini {
    width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.footer-mini div {
    font-size: 18px;
    color: #fff;
    cursor: pointer;
}

.footer-mini div span {
    font-size: 20px;
    margin-right: 10px;
    padding-top: 100px;
    font-weight: bolder;
}


.footer-bottom {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.footer-bottom-t {
    background: #0E0F1F;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.257143px;
    color: #FFFFFF;
    margin: 20px auto 0;
    padding: 35px;
    width: 100%;
}

.footer-bottom-t p {
    text-align: center;
    margin: auto;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    width: 750px;
    color: #f9f9ff59;
}

.footer-bottom-t p:nth-child(2) {
    color: #fff;
    margin-top: 20px;
}

.footer-socials {
    background: transparent;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 15px;
    width: 350px;
    margin: 30px 0 80px;
    padding-top: 20px;
}

.footer-socials img {
    height: 30px;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
}

.p-c {
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 800px;
    background: transparent;
    margin: 100px 0 20px
}

.p-c p {
    background: transparent;
    font-size: 18px;
    font-weight: 500px;
    cursor: pointer;
}


/* =======Profile======== */
.sidebar {
    width: 350px;
}

.sidebar img {
    height: 100px;
    margin-bottom: 10px;
}

#name,
#id {
    font-size: 16px;
    width: 100px;
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
}

#id {
    margin-bottom: 30px;
    color: #353945;
    font-size: 14px;
}

.sidebar a p {
    color: #353945;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 18px;
    transition: all 0.3s linear;
}

.sidebar a p:hover {
    transition: all 0.3s linear;
    color: #9757D7;
}

.sidebar a .active_tab {
    color: #9757D7;
}

.profile {
    position: relative;
    display: flex;
    padding: 100px;
}

.profile_section button {
    width: 150px;
    padding: 15px;
    border: 2px solid #9757D7;
    color: #9757D7;
    font-weight: 600;
    border-radius: 30px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s linear;
}

.profile_section button:hover {
    background-color: #9757D7;
    color: #fff;
    transition: all 0.3s linear;
}

.profile_section h3 {
    font-weight: 700;
    font-size: 26px;
}

.profile_section>p {
    margin: 20px 0 40px;
    font-size: 14px;
    width: 650px;
    color: #777E91;
}

.card {
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 350px;
    height: 150px;
}

.card h4 {
    font-size: 18px;
    margin-bottom: 5px;
}

.card p {
    font-size: 14px;
    color: #515154;
}

.card_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.contact_input,
.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact_input div,
.search {
    width: 200px;
    margin-right: 20px;
    border: 1px solid rgba(193, 199, 208, 0.65);
    border-radius: 4px;
    outline: none;
    padding: 0px 10px;
}

.contact_input div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact_input div label {
    font-size: 14px;
    color: #777E91;
}

.contact_input div select {
    padding: 10px;
    border: none;
    width: 70%;
}
.contact_input .contact_dropdown {
    display: flex;
}
.search {
    padding: 0px 10px;
}

.search img {
    margin-right: 10px;
}

.search input {
    outline: none;
    width: 160px;
    border: none;
    padding: 10px;
}

.contacts table {
    margin-top: 50px;
    width: 100%;
    border-collapse: collapse;
}

.contacts table tr {
    border-bottom: 1px solid #7A7B85;
}

.contacts table td {
    color: #4C4E5A;
    font-size: 14px;
    padding: 15px 10px 5px;
}

.cb {
    display: block;
    position: relative;
    cursor: pointer;
}

.cb input {
    visibility: hidden;
    cursor: pointer;
}

.mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 2px solid #93949f;
    border-radius: 3px;
}

.cb:hover input~.mark {
    background-color: #93949f;
}

.cb input:checked~.mark {
    background-color: #93949f;
}

.mark:after {
    content: "";
    position: absolute;
    display: none;
}

.cb input:checked~.mark:after {
    display: block;
}

.cb .mark:after {
    left: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}



/* ============================FISRT LAYER--------------- */
.signin {
    width: 450px;
    margin: auto;
    padding-top: 50px;
    text-align: center;
}

.signin .signin__image {
    height: 250px;
}

.signin p {
    color: #777E91;
    font-weight: 700;
    margin: 20px 0;
    width: 100%;
}

.signin input[type="text"],
.signin select {
    width: 100% !important;
    padding: 15px;
    border: 2px solid #E6E8EC;
    border-radius: 15px;
    margin: 30px 0 10px;
}

.signin select {
    margin: 0;
}

.signin label {
    font-family: Nunito;
    font-weight: 700;
    font-size: 18px;
    line-height: 36px;
    color: #777E91;
    display: block;
    margin: 20px 0 10px;
    text-align: left;
}

.first_input {
    text-align: left;
    color: #777E91;
}

.first_input input {
    cursor: pointer;
}

.first_input span {
    margin: 0 10px;
}

.first_input span:nth-child(2) {
    margin-right: 100px;
}

.signin button {
    width: 200px;
    padding: 15px 30px;
    border: none;
    color: #fff;
    background-color: #9757D7;
    border-radius: 30px;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    font-family: Nunito, sans-serif;
    transition: all 0.3s linear;
    margin: 50px auto;
}

.signin button:hover {
    background-color: #49078c;
    transition: all 0.3s linear;
}

.btns {
    text-align: center;
}

#recover {
    background-color: transparent;
    color: #9757D7;
    width: 100%;
    text-align: center;
}

.NXNmW {
    --ReactInputVerificationCode-itemWidth: 50px;
    --ReactInputVerificationCode-itemHeight: 50px;
    --ReactInputVerificationCode-itemSpacing: 1px
}

.eajqnH {
    margin: auto;
    margin-top: 30px;
    justify-content: space-around !important;
    width: 300px !important;
}

.ReactInputVerificationCode__item {
    font-size: 20px !important;
    font-family: Nunito, sans-serif !important;
    font-weight: 100 !important;
    height: 10px;
    color: #9757D7;
    border: 1px solid #E6E8EC !important;
    border-radius: 10px !important;
    box-shadow: none;
}

.ReactInputVerificationCode__item.is-active {
    box-shadow: none;
    border: 1px solid #9757D7 !important;
}

.signin small {
    font-size: 12px;
    color: red;
}


/* ============================SECOND LAYER--------------- */
.about p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: #777E91;
}

.about .btns a {
    display: block;
}

.about .btns a button {
    font-weight: 100;
}

.about .btns a:nth-child(2) button {
    background-color: transparent;
    color: #9757D7;
    border: 1px solid #9757D7;
    margin-top: -100px;
}

.about .btns a:nth-child(2) button:hover {
    background-color: #9757D7;
    color: #fff;
}

.createID select {
    width: 100%;
}

.createID label {
    margin-bottom: 0;
}

.createID .create_input input {
    margin: 0;
    width: 100%;
    padding: 15px;
    border: 2px solid #E6E8EC;
    border-radius: 15px;
}

.createQ{
    margin-bottom: 20px;
}
.createID .createQ input{
    margin-bottom: 10px;
}

.account {
    width: 800px;
}

.account>p {
    width: 400px;
    margin: 20px auto 40px;
}

.oneStep_details {
    width: 700px;
    background: linear-gradient(80.73deg, #730DC5 -7.35%, #D34E76 55.84%);
    border-radius: 8px;
    border: none;
    padding: 30px;
    margin: auto;
}

.copy {
    text-align: right;
    color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: right;
    align-items: center;
}

.copy img {
    height: 20px !important;
    margin: 0 0 0 10px;

}

.oneStep_detail {
    margin-top: 40px;
    font-size: 18px;
}

.oneStep_detail p:nth-child(1) {
    color: #fff;
    font-family: chromate;
}

.oneStep_detail p:nth-child(2) {
    color: #FCFCFD;
    opacity: 0.5;
    margin-top: -10px;
    font-family: Nunito, sans-serif;
}

.recovery {
    width: 500px;
}

.recovery ul {
    margin: 50px;
    text-align: left;
    font-size: 15px;
    color: #AAAAAA;
}

.recovery ul li {
    margin-bottom: 20px;
}


/* =================THIRD LAYER============== */
.recoveryID {
    width: 400px;
}

.recovery_method {
    margin: 100px 0;
}

.recovery_method .recovery_btn {
    background-color: transparent;
    color: #9757D7;
    border: 1px solid #9757D7;
    margin: 0 0 20px;
    display: flex;
    width: 100%;
    text-align: left;
}

.recovery_method .recovery_btn:hover {
    background-color: #e0c5fb;
    text-align: right;
}

.recovery_method .recovery_btn span {
    font-size: 14px;
}

.recovery_method .recovery_btn img {
    height: 20px;
    margin-right: 10px;
}

.recovery_messenger {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    width: 330px;
    margin: 50px auto;
}

.recovery_messenger img {
    height: 50px;
    cursor: pointer;
}



@media screen and (min-width: 768px) and (max-width: 1139px) {
    .menu {
        display: none;
    }
    .details img{
        width: 250px;
    }
    .profile{
        padding: 100px 40px 
    }
    .sidebar{
        width: 400px;
    }
    .oneStep_details,.account {
        width: auto;
    }
    .profile_section>p {
        width: auto;
    }
    .card_container {
        gap: 10px;
    }
    .card{
        width: 250px;
    }
    .p-c{
        width: 700px;
    }
    .footer-top, .footer-bottom-t p{
        width: auto;
    }
    .contact_input{
        width: 550px;
        flex-direction: column;
        margin: auto;
    }
    .contact_input .contact_dropdown {
        padding:0;
        margin: 0 auto;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .contact_input .search {
        margin: 0 auto;
        width: 70%;
    }
}

@media screen and (max-width: 767px) {
    header {
        padding: 10px;
    }

    .menu {
        display: block;
    }

    .header_btns {
        position: relative;
    }

    .small_sidebar {
        position: absolute;
        top: 35px;
        width: 200px;
        right: -10px;
        height: 100vh;
        background-color: #fff;
        border-left: 1px solid #777E91;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-top: 30px;
    }
    
    .small_sidebar button {
        width: 150px;
        margin: 0;
        margin-bottom: 20px;
    }
    .signin_bar {
        display: block;
    }
    .signin_bar a p{
        color: #353945;
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 14px;
        transition: all 0.3s linear;
    }
    .signin_bar a:last-child p{
        margin-bottom: 100px;
    }
    .signin_bar a p:hover{
        transition: all 0.3s linear;
        color: #9757D7;
    }
    .signin_bar .active_tab {
        color: #9757D7;
    }
    #signout_btn{
        background-color: #9757D7;
        color: #fff;
    }

    .hero {
        height: 100vh;
    }

    .logo a {
        font-size: 14px;
    }

    .header_btns button {
        padding: 10px 15px;
        font-size: 12px;
    }

    .header_btns {
        display: flex;
        justify-content: right;
        margin-left: 10px;
    }

    .container {
        padding: 10px;
    }

    .container h2 {
        font-size: 20px;
    }

    .hero img {
        height: 150px;
        margin: 100px 0 10px;
    }

    .hero h2 {
        margin: auto;
        width: auto;
        max-width: 300px;
    }

    .hero p {
        margin: 20px auto;
        width: auto;
        line-height: 24px;
    }

    .details {
        padding: 0 20px;
        flex-direction: column-reverse;
    }

    .details p {
        font-size: 14px;
        margin: 15px 0;
        width: auto;
    }

    .details button {
        width: 150px;
        padding: 10px 15px;
    }

    .details img {
        height: 120px;
        margin-bottom: 50px;
    }

    .footer-top {
        margin: auto;
        width: auto;
    }

    .footer-top p,
    .footer-mini {
        width: auto;
        padding: 10px;
    }

    .footer-socials {
        width: 300px;
        grid-template-columns: repeat(6, 1fr);
        gap: 10px 0px;
        margin: 30px auto 20px;
    }

    .p-c {
        width: 300px;
        margin: 20px 0;
    }

    .p-c p {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .footer-bottom-t p {
        width: auto;
    }

    .signin {
        width: 320px !important;
    }

    .signin img {
        margin-top: 30px;
        height: 150px;
    }

    .signin label {
        font-size: 15px !important;
    }

    #messenger {
        width: 320px;
    }

    .back a {
        margin: 40px 0;
        position: fixed;
        background-color: #fff;
        padding-top: 15px;
    }

    .recovery_btn img {
        height: 20px
    }

    .recovery_messenger img {
        height: 50px
    }

    /* =========SECOND LAYER======== */
    .oneStep_details {
        width: auto;
    }
    .about p {
        text-align: left;
    }

    .copy img {
        margin: 0 0 0 10px;
    }

    .account>p {
        width: auto;
    }

    .recovery ul li {
        width: 250px;
    }

    /* =================THIRD LAYER============== */
    .recovery_method .recovery_btn {
        justify-content: left;
        align-items: center;
        padding: 15px;
    }

    .recovery_method .recovery_btn img {
        margin-top: 0;
    }

    .recovery_messenger {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 0;
    }

    /* ===========PROFILE========== */
    .sidebar {
        display: none;
    }

    .profile {
        padding: 70px 20px;
    }

    .profile_section>p {
        width: auto;
    }

    .profile_top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: row-reverse;
    }

    .profile_section button {
        width: 100px;
        height: 40px;
        padding: 0;
    }

    .profile_section h3 {
        font-size: 22px;
        width: 240px;
    }

    .card_container {
        grid-template-columns: repeat(1, 1fr);
    }

    .card {
        width: 338px;
        margin: auto;
    }

    .contact_input {
        width: 320px;
        flex-direction: column;
        margin: auto;
    }

    .contact_input .contact_dropdown div{
        margin:0;
        width: 150px;
    }
    .contact_input .contact_dropdown {
        padding:0;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .contact_input div select {
        width: 60%;
    }
    .contact_input .search {
        margin: 0 auto;
        width: 100%;
    }

    .contact_input .search img {
        margin-right: 0px;
        height: 20px;
        padding: 0;
    }

    .search input {
        display: block;
        width: 100%;
        margin: auto;
    }

    .contacts table {
        width: auto;
        margin: 50px auto
    }
    .contacts table tr{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .contacts table tr td:first-child{
        grid-row: 1/ span 3;
    }
    .contacts table tr td:nth-child(5){
        width: 100%;
    }
}