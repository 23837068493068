.terms__hero {
    padding: 200px 100px;
    background: linear-gradient(90deg, #5f72ff 0%, #73d6f1 100%);
    opacity: 0.8;
    color: var(--sub-white-color);
}

.terms__hero i {
    font-size: 50px;
}
.terms__hero h3 {
    font-weight: 700;
    font-size: 68px;
    line-height: 76px;
    height: 76px;
    margin: 30px 0;
}
.terms__hero p {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    width: 964px;
}

.terms__about {
    text-align: center;
    padding: 150px 100px;
    background: #13142c;
    color: var(--sub-white-color);
}

.terms__about h3,
.terms__rights .rights__top h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    margin: 40px 0;
}
.terms__about p {
    width: 737px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
}
.terms__about .terms__service {
    margin-top: 100px;
}
.terms__about .terms__service h3 {
    width: 558px;
    margin: 0 auto 20px;
}

.terms__about .service__cards {
    margin-top: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1046px;
    margin: 100px auto 0;
}
.terms__about .service__cards .service__card {
    text-align: left;
    width: 483px;
    min-height: 418px;
    margin-bottom: 40px;
}
.terms__about .service__cards .service__card h5,
.terms__about .indemnity h5 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin: 20px 0;
}
.terms__about .service__cards .service__card p {
    width: 483px;
}
.terms__about .indemnity p {
    width: 1046px;
    height: 168px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
}

.terms__rights {
    background: #271e46;
    padding: 100px;
    color: var(--sub-white-color);
}
.terms__rights .rights__top {
    text-align: center;
}
.terms__rights .right__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1041px;
    margin: 50px auto;
}
.terms__rights .right__cards .right__card {
    width: 483px;
    min-height: 139px;
}
.terms__rights .right__cards .right__card h5 {
    font-weight: 700;
font-size: 32px;
line-height: 40px;
margin: 20px 0;
}
.terms__rights .right__cards .right__card p {
    width: 483px;
    font-weight: 400;
font-size: 16px;
line-height: 24px;
}

.terms__aceeptance {
    background: #13142c;
    margin-top: -20px;
    padding: 100px 0;
    color: var(--sub-white-color);
    text-align: center;
}
.terms__aceeptance h3 {
    width: 460px;
    height: 96px;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    margin: 20px auto;   
}
.terms__aceeptance p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    width: 1046px;
    margin: 0 auto;
}
.terms__aceeptance .terms__disclaimer {
    margin-top: 100px;
}
.terms__aceeptance .terms__disclaimer h3 {
    margin-bottom: 0;
}
.terms__aceeptance img {
    width: 100%;
    margin-top: 100px;
}


@media (max-width: 912px) {
    .terms__hero {
        padding: 200px 20px;
    }
    .terms__hero h3 {
        font-size: 30px;
        margin: 10px 0
    }
    .terms__hero p {
        font-size: 18px;
        width: 100%;
    }
    .terms__about {
        padding: 100px 20px
    }
    .terms__about h3, .terms__rights .rights__top h3 {
        font-size: 28px;
        line-height: 40px;
    }
    .terms__about p {
        width: 100%;
    }
    .terms__about .terms__service h3 {
        width: 100%
    }
    .terms__about .service__cards, .terms__rights .right__cards {
        width: 100%;
    }
    .terms__about .service__cards .service__card h5, .terms__about .indemnity h5 {
        font-size: 24px;
    }
    .terms__about .service__cards .service__card  {
        width: 100%;
    }
    .terms__about .service__cards .service__card p {
        width: 100%;
    }
    .terms__about .indemnity {
        height: 1000px;
    }
    .terms__about .indemnity p {
        width: 100%;
    }
    .terms__rights {
        width: 100%;
        padding: 100px 20px
    }
    .terms__rights .right__cards .right__card {
        width: 100%;
    }
    .terms__rights .right__cards .right__card p {
        width: 100% !important;
    }
    .terms__aceeptance h3{
        width: 100%;
        font-size: 24px;
        line-height: 38px;
    }
    .terms__aceeptance p {
        width: 100%;
        padding: 0 20px;
    }
}